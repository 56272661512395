<template>
  <div>
    <!-- modal -->
    <!-- <b-modal
      id="modal-primary"
      :ok-only="false"
      ok-title="บันทึกข้อมูล"
      cancel-title="ยกเลิก"
      modal-class="modal-primary"
      :title="_title"
      size="sm"
      @cancel="clickCancel"
    > -->

    <b-modal ref="modal-primary" id="modal-primary" hide-footer title="Using Component Methods">
      <b-card-text>
        <validation-observer ref="formp">
          <b-form>
            <b-row>
              <b-col cols="12">
                <validation-provider #default="{ errors }" name="codeDay" rules="required">
                  <b-form-group label="ผูกดวงประจำวัน" label-for="b-codeDay">
                    <v-select
                      v-model="form.name"
                      label="title"
                      :options="['จันทร์', 'อังคาร', 'พุธ', 'พฤหัส', 'ศุกร์', 'เสาร์', 'อาทิตย์']"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <!-- price -->
              <b-col cols="12">
                <b-form-group label="ราคา" label-for="a-price">
                  <validation-provider #default="{ errors }" name="price" rules="required|numeric">
                    <b-form-input
                      id="a-price"
                      v-model="form.price"
                      :state="errors.length > 0 ? false : null"
                      type="number"
                      placeholder="กรอก ราคา"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="สีตัวอักษร" label-for="c-code">
                  <b-form-input id="c-code" type="color" v-model="form.color_code_data_id" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-text>

      <b-row>
        <b-col sm="12">
          <b-button class="mt-1" variant="outline-danger" block @click="$bvModal.hide('modal-primary')"
            >ยกเลิก</b-button
          >
          <b-button class="mt-2" variant="outline-warning" block @click="clickSave">บันทึก</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, VBModal } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, numeric, double } from '@validations'
import vSelect from 'vue-select'
export default {
  data() {
    return {
      required,
      numeric,
      double,

      form: {
        id: '',
        code: '',
        price: '',
        credit: '',
      },
    }
  },

  computed: {
    _title() {
      return `${this.form.id ? 'แก้ไข' : 'เพิ่ม'} ข้อมูลราคา`
    },
  },
  components: {
    BButton,
    BModal,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  methods: {
    show(data = '') {
      if (data) this.form = data
      this.$bvModal.show('modal-primary')
    },
    async clickSave() {
      const validate = await this.$refs.formp.validate()
      if (!validate) {
        return ''
      }
      // console.log(4)
      const data = Buffer.from(JSON.stringify(this.form)).toString('base64')
      // console.log(4)
      const resp = await this.api.createOrUpdate(
        '/api/admin/admin-topic-image-lists',
        { option: data, id: this.form.id },
        true,
      )
      // console.log(resp)
      this.$bvModal.hide('modal-primary')
      this.$emit('reload', '')
    },
    clickCancel() {},
  },
}
</script>
